<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Created at
          </th>
          <th class="text-left">
            Type
          </th>
          <th class="text-left">
            Session ID
          </th>
          <th class="text-left">
            Function
          </th>
          <th class="text-left">
            Message
          </th>
          <th class="text-left">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="log in logs"
          :key="log.id"
          :style="{ 'background-color': timeRowColor(log) }"
        >
          <td>
            {{ formatDatetimeLocal(log.createdAt) }}
          </td>
          <td>{{ log.type }}</td>
          <td>{{ log.sessionId }}</td>
          <td>{{ log.functionName }}</td>
          <td>{{ log.message }}</td>
          <td>
            <v-icon
              small
              class="mr-2"
              @click="showItem(log.id)"
            >
              {{ mdiEye }}
            </v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mdiEye } from '@mdi/js'

export default {
  props: {
    scheduleRef: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mdiEye,
      logs: []
    }
  },
  mounted() {
    return this.$bind(
      'logs',
      this.$firebase
        .firestore()
        .collection('logs')
        .where('schedule', '==', this.scheduleRef)
        .orderBy('createdAt', 'desc')
    )
  },
  methods: {
    timeRowColor(log) {
      return log.type === 'error' ? '#FFEBEE' : '#FFFFFF'
    },
    showItem(id) {
      this.$router.push({ name: 'logShow', params: { id } })
    }
  }
}
</script>
