<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Planned time
          </th>
          <th class="text-left">
            Status
          </th>
          <th class="text-left">
            Sent time
          </th>
          <th class="text-left">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="time in times"
          :key="time.sendTime.seconds"
          :style="{ 'background-color': timeRowColor(time) }"
        >
          <td>
            {{ formatDatetimeLocal(time.sendTime) }}
          </td>
          <td>{{ getStatusName(time.sendStatus) }}</td>
          <td>
            <template v-if="time.sentTime">
              {{ formatDatetimeLocal(time.sentTime) }}
            </template>
          </td>
          <td>
            <v-icon
              small
              class="mr-2"
              @click="showItem(time.id)"
            >
              {{ mdiEye }}
            </v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mdiEye } from '@mdi/js'
import moment from '@/plugins/moment'
import { SENT, getStatusName } from '@/kit/dictionaries/timesSendStatus'

export default {
  props: {
    times: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mdiEye,
      getStatusName
    }
  },
  methods: {
    timeRowColor(time) {
      if (moment(time.sendTime.toDate()) < moment()) {
        return time.sendStatus === SENT ? '#E8F5E9' : '#FFEBEE'
      }
    },
    showItem(id) {
      this.$router.push({ name: 'notificationShow', params: { id } })
    }
  }
}
</script>
