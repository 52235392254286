<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="data in items">
          <tr
            v-if="data.value"
            :key="data.name"
          >
            <td>{{ data.name }}</td>
            <td v-html="data.value" />
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import reminders from '@/kit/dictionaries/reminders'
import getContact from '@/kit/helpers/contact'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      return [
        {
          name: 'ID',
          value: this.item.id
        },
        {
          name: 'Created at',
          value: this.formatDatetimeLocal(this.item.createdAt)
        },
        {
          name: 'Reminder type',
          value: this.getReminder(this.item.reminder).title
        },
        {
          name: 'Activity',
          value: this.item.activity.title
        },
        {
          name: 'Remind client to',
          value: this.item.remind
        },
        {
          name: 'Name',
          value: this.item.name
        },
        {
          name: 'Contact',
          value: getContact(this.item)
        },
        {
          name: 'Message',
          value: this.item.message
        },
        {
          name: 'Notification type',
          value: this.item.activity.notificationConfig.type
        },
        {
          name: 'Time',
          value:
            this.item.time &&
            this.item.activity.notificationConfig.type === 'daily'
              ? this.getTimes(this.item.time)
              : ''
        }
      ]
    }
  },
  methods: {
    getReminder(id) {
      return reminders[0].reminders.find(reminder => reminder.id === id)
    },
    getTimes(time) {
      return time.map(item => this.formatTimeUtcToLocal(item)).join(', ')
    }
  }
}
</script>
